import axios from 'axios';
import dayjs from 'dayjs';
import { useState } from 'react';
import Calendar from 'react-calendar';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import defaults from 'Shared/useQuery';

import Group from './Group';

const fetchPacked = ({ companyName }) =>
   axios.get('/warehouse/groups/packed-for-manager', {
      params: {
         company_name: companyName,
      },
   });

const ForTransport = () => {
   const [filtred, setFiltred] = useState([]);
   const filters = useSelector((s) => s.warehouse.filters);
   const [groups, setGroups] = useState([]);
   const { refetch } = useQuery(
      ['fetchPacked', { companyName: filters.companyName }],
      () => fetchPacked({ companyName: filters.companyName }),
      {
         ...defaults,
         onSuccess: ({ data }) => {
            setGroups(data.groups);
            refetch();
         },
      },
   );

   const onChange = (d) => {
      const formattedDate = dayjs(d).format('YYYY-MM-DD');

      setFiltred(
         groups.filter(
            (group) =>
               group.status !== 'SEND' &&
               group.palettes.some(
                  (palette) =>
                     palette?.sendingPlannedAt && palette.sendingPlannedAt === formattedDate,
               ),
         ),
      );
   };

   const rendered = filtred.map((g) => <Group key={g.id} group={g} />);

   return (
      <Container>
         <CalendarContainer>
            <Calendar onChange={onChange} className="sm" locale="pl" />
         </CalendarContainer>
         <table className="table table-hover">
            <thead>
               <tr>
                  <th>Grupa</th>
                  <th>Palety</th>
               </tr>
            </thead>
            <tbody>{rendered}</tbody>
         </table>
      </Container>
   );
};

const Container = styled.div`
   margin-top: 2rem;
`;

const CalendarContainer = styled.div`
   margin: 2rem 24%;
`;

export default ForTransport;
