import axios from 'axios';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import InternalContainer from 'Layouts/InternalContainer';
import { defaultsOneTime } from 'Shared/useQuery';

import { Context } from './Context';
import Filters from './Filters/Filters';
import ForTransport from './ForTransport/ForTransport';

const fetchClients = () => axios.get('/warehouse/clients');

const Transport = () => {
   const dispatch = useDispatch();
   const filters = useSelector((s) => s.warehouse.filters);
   const { refetch: refetchClients } = useQuery(['fetchClients'], fetchClients, {
      ...defaultsOneTime,
      enabled: !!filters.orderPartsGroupStatus,
      onSuccess: ({ data }) => {
         dispatch({
            type: 'FETCH_CLIENTS',
            clients: data.clients,
         });
      },
   });

   const refetch = () => {
      refetchClients();
   };

   const value = useMemo(
      () => ({
         refetch,
      }),
      [refetch],
   );

   return (
      <Context.Provider value={value}>
         <Helmet>
            <title>Magazyn | Konsport</title>
         </Helmet>
         <InternalContainer>
            <div className="row">
               <div className="col">
                  <HeaderContainer>
                     <h1>Transporty</h1>
                  </HeaderContainer>
                  <Filters />
                  <ForTransport />
               </div>
            </div>
         </InternalContainer>
      </Context.Provider>
   );
};

const HeaderContainer = styled.div`
   margin-bottom: 2rem;
`;

export default Transport;
