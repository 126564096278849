import { useDispatch } from 'react-redux';
import Select from 'react-select';
import styled from 'styled-components';

import { filterChanged } from 'state/warehouse';

const driverOptions = [
   { label: 'Wszyscy kierowcy', value: '' },
   { label: 'Jarek Grzelak tel. 501 681 814', value: 'jarek_grzelak' },
   { label: 'Marcin Niewadził tel. 721 388 092', value: 'marcin_niewadził' },
   { label: 'Patryk tel. 669 396 926', value: 'patryk' },
   { label: 'Karol Gortat tel. 609 999 311', value: 'karol_gortat' },
   { label: 'Przemek Bujnowicz tel. 664 478 793', value: 'przemek_bujnowicz' },
];

const DriverFilter = ({ className }) => {
   const dispatch = useDispatch();

   return (
      <div className={className}>
         <Label>
            Kierowca
            <Select
               placeholder=""
               defaultValue=""
               onChange={(e) => dispatch(filterChanged('driver', e.value || ''))}
               name="driverFilterSelect"
               options={driverOptions}
               variant="neutral"
            />
         </Label>
      </div>
   );
};

const Label = styled.label`
   width: 100%;
`;

export default DriverFilter;
